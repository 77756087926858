<template>
  <div class="hikvision-stream-container layout__body">
    <!-- 
      loading
    -->
    <v-overlay v-if="loading" opacity="1" :color="'#FFF'">
      <CPLoading />
    </v-overlay>
    <!-- 
      error
     -->
    <DashMessage
      v-if="serverErr || noStreams && !loading"
      :message="message"
      hasXClose
      hasBtn
      @clear="handleBackNavigation"
      @btn-pressed="handleBackNavigation"
    ></DashMessage>
    <template>
      <div class="hikvision-stream-container">
        <div
          v-for="({link, title}, i) in URLS"
          :key="i"
          >
          <div class="video-stream" @click="showPlayer(link)">
            <div class="icon"><img src="@/assets/dashboard/icons/live-video.svg" alt=""></div>
            <div class="label"><p>{{ title }}</p></div>
            <div><img src="@/assets/dashboard/icons/nav-arrow-right.svg" alt=""></div>
          </div>
        </div>
      </div>
    </template>
    <JSDecoder
      v-if="play"
      :URL="curStream"
      @close="closePlayer()"
    />
  </div>
</template>

<script>
import { backNavigateQueue } from "@/helpers/app.js";
import dashboardApi from '@/helpers/dashboardApi';
import JSDecoder from "@/components/Dashboard/JSDecoder.vue";
import DashMessage from '@/components/Dashboard/DashMessage.vue'
import CPLoading from '@/components/Dashboard/CPLoading.vue'
import bridge from "@/helpers/bridge";

export default {
  components: {
    DashMessage,
    JSDecoder,
    CPLoading,
  },
  data() {
    return {
      scriptEle: null,
      loading: true,
      serverErr: false,
      URLS: {},
      play: false,
      playFullScreen: false,
      curStream: null,
    }
  },
  mounted() {
    backNavigateQueue.set(this.handleBackNavigation);
    bridge.disableScreenCapture();
    this.$store.dispatch('layout/setHeaderTitle', 'My video');
  },
  methods: {
    // show player
    showPlayer(link) {
      this.curStream = link;
      this.play = !this.play;
    },

    // close player
    closePlayer() {
      this.play = false;
    },
    handleBackNavigation() {
      this.$router.replace({
        name: 'MainPage'
      })
    },
    async getURLS() {
      try {
        const res = await dashboardApi(`/cameras/list`);
        this.URLS = res.data.urls;
      } catch (error) {
        console.log(error);
        this.$store.dispatch('layout/alert', error.message, error)
        this.serverErr = true;
      } finally {
        this.loading = false;
      }
    },
    dynamicallyLoadScript(url) {
      var script = document.createElement("script");
      script.src = url;
      this.scriptEle = document.body.appendChild(script);
    },
    loadcss(url) {
      let style = document.createElement('link');
      style.type = "text/css";
      style.rel = "stylesheet";
      style.href = url;
      document.head.appendChild(style);
    }
  },
  async created() {
		this.dynamicallyLoadScript('/js/videoStream/jsPlugin-1.2.0.min.js');
    this.getURLS();
  },
  beforeDestroy() {
    document.body.removeChild(this.scriptEle);
    bridge.enableScreenCapture();
  },
  computed: {
    message() {
      return this.serverErr ? 'Ошибка сервера' : Object.keys(this.URLS).length === 0 ? 'Трансляции не найдены' : null
    },
    noStreams() {
      return Object.keys(this.URLS).length === 0 && true
    },
  },
}
</script>
