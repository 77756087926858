<template>
	<v-overlay :opacity="fullscreen === true ? 1 : 0.4">
		<div class="hikvision">

			<div class="player-container" :class="{rotate: fullscreen}">
				<div :hidden="overlay" id="playWind" class="player"></div>
				<div
					class="video-loading" 
					:style="{ 'height': playerConf.iHeight + 'px', 'width': playerConf.iWidth + 'px'}"
					v-if="overlay"
				>
					<v-progress-circular
						indeterminate
						color="#EF7F1A"
						size="64"
					></v-progress-circular>
				</div>
			</div>

			<!-- btns -->
			<div v-if="!fullscreen" class="btns">
				<div class="btn">
					<img @click="setFullscreen" src="@/assets/dashboard/icons/fullscreen.svg" alt="">
				</div>
				<div class="btn">
					<img @click="close" src="@/assets/dashboard/icons/x-lg.svg" alt="">
				</div>
			</div>

			<!-- fullscreen btns -->
			<div v-if="fullscreen">
				<div class="close">
					<div class="btn_fs">
						<img @click="close" src="@/assets/dashboard/icons/x-lg.svg" alt="">
					</div>
				</div>
				<div class="toggleFS">
					<div class="btn_fs">
						<img @click="setFullscreen" src="@/assets/dashboard/icons/fullscreen-exit.svg" alt="">
					</div>
				</div>
			</div>
		</div>
	</v-overlay>
</template>

<script>

export default {
	props: {
		URL: {
			type: String
		}
	},
	data() {
		return {
			// js decoder config
			playerConf: {
				szId: "playWind",
				iType: 2,
				iWidth: null,
				iHeight: null,
				iMaxSplit: 1,
				iCurrentSplit: 1,
				szBasePath: "/js/videoStream",
				oStyle: {
            border: "#343434",
            borderSelect: "red",
            background: "#4C4B4B"
        }
			},
			jsDecoder: null,
			iWind: 0,

			// layout
			overlay: true,

			// fullscreen
			fullscreen: false,
			fsWidth: null,
			fsHeight: null,
		}
	},
	created() {
		// default width / height
		this.playerConf.iWidth = window.innerWidth;
		this.playerConf.iHeight = (window.innerWidth * 66.6) / 100;

		// fullscreen
		this.fsHeight = window.innerWidth;
		this.fsWidth = Math.trunc(window.innerWidth + (window.innerWidth * 66.6) / 100);
	},
	mounted() {
		if (!this.jsDecoder) {
			this.jsDecoder = new JSPlugin(this.playerConf); // eslint-disable-line
		}
		this.jsDecoder && this.play();
	},
	methods: {
		play() {
			this.jsDecoder.JS_Play(this.URL, {
				playURL: this.URL,//通道预览URL, 流媒体
				auth: '',  //认证信息, 设备用户名&密码
				token: ''  //token
			}, this.iWind).then(() => {
					console.log("realplay success");
					this.overlay = false;
			},function () {
			var html = "realplay failed";
			console.log(html);
			// document.getElementById("error").innerHTML = "<div>" + html + "</div>";
			});
		},
		stop() {
			this.jsDecoder.JS_Stop(this.iWind);
    },
		close() {
			this.$emit('close')
		},
		setFullscreen() {
			console.log('fullscreen btn pressed!');
			// this.$emit('fullscreen');
			this.fullscreen = !this.fullscreen;
			this.fullscreen !== true ? this.JS_Resize(this.playerConf.iWidth, this.playerConf.iHeight) : this.JS_Resize(this.fsWidth, this.fsHeight);
		},
		JS_Resize(w, h) {
			// this.jsDecoder.JS_Resize(this.fsWidth, this.fsHeight);
			this.jsDecoder.JS_Resize(w, h);
		},
		destroyWorker() {
			this.jsDecoder.JS_DestroyWorker();
		},
	},
	beforeDestroy() {
		this.jsDecoder.JS_Stop(this.iWind);
	},
	destroyed() {
		this.destroyWorker()
	},
}
</script>

<style lang="scss" scoped>
</style>