<template>
  <div class="form__fullscreen">
    <div 
      v-if="hasXClose"
      class="form__fullscreen__header"
    >
      <div 
        class="form__fullscreen__header__x-container"
        :class="{
          'form__fullscreen__header__x-container--hidden': isLoading,
        }" 
        @click="handleCloseClick"
      >
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:width="'26'" v-bind:height="'26'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.293 20.294a1 1 0 001.414 1.414l6.294-6.294 6.295 6.296a1 1 0 001.415-1.414L15.415 14l6.293-6.293a1 1 0 10-1.414-1.414L14 12.586 7.71 6.295a1 1 0 00-1.414 1.414L12.586 14l-6.293 6.294z" fill="#151C22"/></svg>
      </div>
    </div>
    <div 
      class="form__fullscreen__main-content"
      :class="{
        'form__fullscreen__main-content--centered': isContentCentered
      }"
      :style="{
        backgroundImage: bgWatermarkImage,
      }"
    >
      <slot />
    </div>
    <template>
      <!-- TODO rewrite in vue3 component -->
      <div 
        v-if="$slots.footer"
        class="form__fullscreen__footer-content"
        :style="{
          background: footerBgColor,
        }"
        ref="footerEl"
      >
        <slot name="footer"/> 
      </div>
      <!-- FOOTER SPACER -->
      <div
        v-if="footerHeight"
        :style="{
          marginTop: footerHeight + 'px'
        }"
      ></div>
      <!-- FOOTER SPACER -->
    </template>
  </div>
</template>

<script>
import { generateWatermarkImageUri } from "@/helpers/utils";

export default {
  props: [
    'watermark', 
    'isLoading', 
    'hasXClose',
    'footerBgColor',
    'isContentCentered'
  ],
  data(){
    return {
      bgWatermarkImage: undefined,
      footerHeight: 0,
    }
  }, 
  watch: {
    watermark:{
      handler(newValue){
        if (newValue){
          this.bgWatermarkImage = generateWatermarkImageUri(newValue);
        } else {
          this.bgWatermarkImage = undefined
        }
      },
    },
    '$slots.footer': {
      immediate: true,
      handler(){
        this.$nextTick(() => {
          this.footerHeight = this.$refs['footerEl']?.offsetHeight || 0;
        })
      },
    }
  },

  mounted(){
    this.$root.$el.style.overflow = 'hidden';
    if (this.watermark){
      this.bgWatermarkImage = generateWatermarkImageUri(this.watermark);
    }
  },


  beforeDestroy(){
    this.$root.$el.style.overflow = null;
  },

  methods: {
    handleCloseClick(){
      if(!this.isLoading){
        this.$emit('clear');
      }
    }
  }
}
</script>
<style lang="scss">
.form__fullscreen{
  position: fixed;
  overflow-y: hidden;
  top: 0; 
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #FFF;
  z-index: 10;
  display: flex;
  flex-direction: column;
  &__header{
    padding: 20px 12px;
    display: flex;
    &__x-container{
      margin-left: auto;
      &--hidden{
        opacity: 0;
      }
    }
  }
  &__main-content{
    flex-grow: 1;
    padding: 24px 0;
    overflow-y: auto;
    position: relative;
    background-repeat: repeat;
    ul {
      padding-left: 1rem;
    }
    ol {
      padding-left: 1rem;
    }
    &--centered{
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 128px;
    }
  }
  &__footer-content{
    background-color: #FFFFFF;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 3;
  }
}


</style>