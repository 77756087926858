var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form__fullscreen"},[(_vm.hasXClose)?_c('div',{staticClass:"form__fullscreen__header"},[_c('div',{staticClass:"form__fullscreen__header__x-container",class:{
        'form__fullscreen__header__x-container--hidden': _vm.isLoading,
      },on:{"click":_vm.handleCloseClick}},[_c('svg',{attrs:{"width":"28","height":"28","viewBox":"0 0 28 28","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"width":'26',"height":'26',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M6.293 20.294a1 1 0 001.414 1.414l6.294-6.294 6.295 6.296a1 1 0 001.415-1.414L15.415 14l6.293-6.293a1 1 0 10-1.414-1.414L14 12.586 7.71 6.295a1 1 0 00-1.414 1.414L12.586 14l-6.293 6.294z","fill":"#151C22"}})])])]):_vm._e(),_c('div',{staticClass:"form__fullscreen__main-content",class:{
      'form__fullscreen__main-content--centered': _vm.isContentCentered
    },style:({
      backgroundImage: _vm.bgWatermarkImage,
    })},[_vm._t("default")],2),[(_vm.$slots.footer)?_c('div',{ref:"footerEl",staticClass:"form__fullscreen__footer-content",style:({
        background: _vm.footerBgColor,
      })},[_vm._t("footer")],2):_vm._e(),(_vm.footerHeight)?_c('div',{style:({
        marginTop: _vm.footerHeight + 'px'
      })}):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }