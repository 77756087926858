<template>
    <Fullscreen
      :hasXClose="hasXClose"
      @clear="$emit('clear')"
      :isContentCentered="true"
      >
      <div class="dash__error">
        <h5>{{ message }}</h5>
        <v-btn v-if="hasBtn" color="#EF7F1A" class="dash__error__btn" @click="$emit('btn-pressed')">Назад</v-btn>
      </div>
    </Fullscreen>
</template>

<script>
import Fullscreen from '@/components/reusable/Fullscreen.vue';

export default {
  components: {
    Fullscreen
  },
  props: {
    hasXClose: Boolean,
    message: {
      type: String,
      required: true,
      default: 'Информация не найдена',
    },
    hasBtn: Boolean,
  },
}
</script>